import './App.css'
import { motion } from 'framer-motion';

interface ProjectProps {
  image: string;
  title: string;
  description: string;
  url: string;
}

const my = {
  name: "Musab",
  email: "musab@kedruga.com"
}

const projects = [
  {
    image: process.env.PUBLIC_URL + "/assets/nft_observer.png",
    title: "NFT Observer",
    description: "React, Strapi CMS, PostgreSQL",
    url: "https://kedruga.com/nftobserver"
  },
  {
    image: process.env.PUBLIC_URL + "/assets/plet.png",
    title: "Plet",
    description: "React, Node.js, Express.js, Plaid API, Bitcoin.js, Supabase",
    url: "https://plet.vercel.app"
  },
  {
    image: process.env.PUBLIC_URL + "/assets/kedruga_extension.png",
    title: "Kedruga Extension",
    description: "Javascript, Twitch API, HTML, Bootstrap CSS",
    url: "https://twitch.tv/ext/7pq1j216n28bjjzbxkm84sjtnlovux"
  },
  {
    image: process.env.PUBLIC_URL + "/assets/aimost.png",
    title: "AImost",
    description: "Unity, C#, WebGL",
    url: "https://endlete.itch.io/aimost"
  }
]

const socials = [
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/in/musabshakil/",
  },
  {
    title: "GitHub",
    url: "https://github.com/MusabShakeel576",
  },
  {
    title: "Blog",
    url: "https://dev.to/musabshakil",
  }
]

const variants = {
  offscreen: { opacity: 0, y: "100px" },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 1
    }
  },
}

const Arrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg>
  )
}

const Navbar = () => {
  return (
    <nav>
      <motion.ul
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={variants}
      >
        <li><span className="title"><strong>{my.name}</strong></span></li>
        <li><a className="email link" href={`mailto:${my.email}`}>{my.email}<Arrow /></a></li>
      </motion.ul>
    </nav>
  )
}

const Project = ({ image, title, description, url }: ProjectProps) => {
  return (
    <motion.div
      className="project"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={variants}
    >
      <a href={url} target="_blank" rel="noreferrer noopener">
        <motion.img
          src={image}
          alt={title}
          initial={{ opacity: 1 }}
          whileHover={{ opacity: 0.7 }}
        />
        <p className="title"><strong>{title}</strong></p>
        <p className="description">{description}</p>
      </a>
    </motion.div>

  );
};

const Projects = () => {
  return (
    <section className="projects">
      {projects.map((project, i) =>
        <Project
          key={i}
          image={project.image}
          title={project.title}
          description={project.description}
          url={project.url}
        />
      )}
    </section>
  )
}

const About = () => {
  return (
    <motion.section
      className="about"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={variants}
    >
      <h2>About</h2>
      <p>
        Software Engineer. Previously worked at <a href="https://twitter.com/EternalApp" target="_blank" rel="noopener noreferrer"
        >Eternal</a> and <a href="https://twitter.com/CluCoin" target="_blank" rel="noopener noreferrer">CluCoin</a>.
      </p>
    </motion.section>
  )
}

const Footer = () => {
  return (
    <motion.footer
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      variants={variants}
    >
      {socials.map((social, i) =>
        <a
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          key={i}
        >
          {social.title}
        </a>
      )}
    </motion.footer>
  )
}

const App = () => {
  return (
    <>
      <Navbar />
      <main>
        <Projects />
        <About />
      </main>
      <Footer />
    </>
  );
};

export default App;
